import { useState } from 'react'
import { OrganisationListInterface, SubscribApplicationInterface, applicationsInterface, subscribeApplicationInitialValues } from '../../../modules/accounts/components/settings/SettingsModel'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { toast } from 'react-toastify'
import { useLocation, useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import axios from 'axios'
import { config } from '../../api-helper/api-header-configs'
import { add_application_in_organisation, add_tenant_general_settings, check_application_tenant, get_single_application } from '../api/applicationsApi'
import { Alert, Form } from 'react-bootstrap'


function AddApplication() {
    const [loading, setLoading] = useState(false)
    const organisationId = localStorage.getItem("current_organisation_id");
    const applicationsArrayList:any=localStorage.getItem("all_application_ids");
   // Parse the string back into an array
    const applicationsArray = JSON.parse(applicationsArrayList);
    const queryClient = useQueryClient()
    const location = useLocation();
    const [frontEndDomain,setFrontEndDomain]=useState("");
    const organisation = (location.state as { organisation: OrganisationListInterface }).organisation;
    const application = (location.state as { application: applicationsInterface }).application;
    const userId: string = JSON.parse(localStorage.getItem('kt-auth-react-v') || '')?.user.id || null;
    const userEmail: string = JSON.parse(localStorage.getItem('kt-auth-react-v') || '')?.user.email || null;  
    // Get Single Application Data
  const { data:applcationDataSingle } = useQuery(
    ['applcationData',application.id],
    () => get_single_application( application.id),
    {
      refetchOnWindowFocus: true,
    }
  );
    const navigate=useNavigate();
    const handleAddApplication = useMutation(add_application_in_organisation, {
        onSettled: (data, error) => {
            if (!error) {
                toast.success(data.message, {
                    position: 'top-right',
                    autoClose: 8000,
                });
                navigate("/organisations/applications");
            } else {
                toast.error('An error occurred. Please try again later.', {
                    position: 'top-right',
                    autoClose: 3000,
                });
            }
        },
    });
    // const handleAddApplications = () => {
    //     const arr = applicationsArray
    //     const uniqueArr = arr.filter((value, index, self) => self.indexOf(value) === index); 
    //     if (applicationsArray && applicationsArray.length > 0) {
    //         // Post the selected applications data here
    //         handleAddApplication.mutate({
    //             organisation_id: localStorage.getItem("current_organsation_id") || organisation.id,
    //             applications: applicationsArray
    //         });
    //     }
    // };
    const add_application = async (values) => {
        setLoading(true); // Start loading spinner
        try {
            const response = await axios.post(
                "https://" + application.api_url + "/api/tenants",
                values,
                config
            );
            return response.data; // Return the response data if needed
        } catch (error:any) {
            // Handle the error case
            toast.error(
                error?.response?.data?.message || "An error occurred. Please try again later.",
                {
                    position: "top-right",
                    autoClose: 3000,
                }
            );
    
            throw error; // Rethrow the error if further handling is needed
        } finally {
            setLoading(false); // Stop loading spinner
        }
    };
    const mutation = useMutation(add_application, {
        onSettled: (data, error) => {
            if (!error) {
                tenantMutation.mutate({
                    organization_id: localStorage.getItem("current_organisation_id"),
                    application_id: application.id
                })
                // toast.success(data.message, {
                //     position: 'top-right',
                //     autoClose: 3000,
                // })
                setLoading(false)
                queryClient.invalidateQueries('organisations')
            } else {
                toast.error('An error occurred. Please try again later.', {
                    position: 'top-right',
                    autoClose: 3000,
                })
                setLoading(false)
            }
        },
    })
    const addGeneralSettingsMutation = useMutation(add_tenant_general_settings, {
        onSettled: (data, error) => {
            if (!error) {
                // toast.success(data.message, {
                //     position: 'top-right',
                //     autoClose: 3000,
                // })
                setLoading(false)
                // Post the selected applications data here
        if (applicationsArray && applicationsArray.length > 0) {
            // Post the selected applications data here
            handleAddApplication.mutate({
                organisation_id:organisationId,
                applications: applicationsArray
            });
        }
                queryClient.invalidateQueries('organisations')
            } else {
                toast.error('An error occurred. Please try again later.', {
                    position: 'top-right',
                    autoClose: 3000,
                })
                setLoading(false)
            }
        },
    })
    const tenantMutation = useMutation(check_application_tenant, {
        onSettled: (data, error) => {
            if (!error) {
                // toast.success(data.message, {
                //     position: 'top-right',
                //     autoClose: 3000,
                // })
                setLoading(false)
                queryClient.invalidateQueries('organisations')
            } else {
                toast.error('An error occurred. Please try again later.', {
                    position: 'top-right',
                    autoClose: 8000,
                })
                setLoading(false)
            }
        },
    })
    // const formik = useFormik<SubscribApplicationInterface>({
    //     initialValues: subscribeApplicationInitialValues,
    //     onSubmit: (values) => {
    //         setLoading(true)
    //         mutation.mutate({
    //             org_name: localStorage.getItem("current_organisation_name"),
    //             organization_id: localStorage.getItem("current_organisation_id"),
    //             user_id: userId,
    //             email: userEmail,
    //             application_id: application.id,
    //             domain_name: values.domain_name,
    //             frontend_domain: values.frontend_domain,
    //             ip_address: values.ip_address,
    //             is_ip_address: 0,
    //         })
    //         addGeneralSettingsMutation.mutate({
    //             organization_id: localStorage.getItem("current_organisation_id"),
    //             user_id: userId,
    //             application_id: application.id,
    //             domain: values.domain_name[0],
    //             frontend_domain: values.frontend_domain[0],
    //         })
    //     },
    // })
    const formik = useFormik<SubscribApplicationInterface>({
        initialValues: subscribeApplicationInitialValues,
        onSubmit: async (values) => {
            setLoading(true); // Start loading
            const isIpAddress = values.ip_address.filter(ip => ip !== null && ip !== '').length > 0 ? '1' : '0';
            try {
                  // Execute all mutations in parallel
            const [applicationResponse, generalSettingsResponse] = await Promise.all([
                mutation.mutateAsync({
                    org_name: localStorage.getItem("current_organisation_name"),
                    organization_id: localStorage.getItem("current_organisation_id"),
                    user_id: userId,
                    email: userEmail,
                    application_id: application.id,
                    domain_name: values.domain_name,
                    frontend_domain: values.frontend_domain,
                    ip_address: values.ip_address,
                    is_ip_address: isIpAddress,
                }),
                addGeneralSettingsMutation.mutateAsync({
                    organization_id: localStorage.getItem("current_organisation_id"),
                    user_id: userId,
                    application_id: application.id,
                    domain: values.domain_name[0],
                    frontend_domain: values.frontend_domain[0],
                }),
            ]);
                // queryClient.invalidateQueries("organisations");
                navigate("/organisations/applications");
            } catch (error) {
                // Handle errors globally
                toast.error("An error occurred. Please try again later.", {
                    position: "top-right",
                    autoClose: 3000,
                });
            } finally {
                setLoading(false); // Stop loading
            }
        },
    });
    
    const handleAddIP = (e) => {
        e.preventDefault();  // Prevent form submission
        formik.setFieldValue('ip_address', [
            ...formik.values.ip_address,
            ''  // Add an empty string for a new IP address
        ]);
    };

    const handleRemoveIP = (index) => {
        if (formik.values.ip_address.length > 1) {
            const updatedIPs = formik.values.ip_address.filter((_, i) => i !== index);
            formik.setFieldValue('ip_address', updatedIPs);
        }
    };
    const handleDomainVerify = async () => {
        try {
          if (frontEndDomain.length === 0) {
            toast.error('No domains provided for verification.', {
              position: 'top-right',
              autoClose: 3000,
            });
            return;
          }
      
          const response = await axios.post(
            `https://${application.api_url}/api/verify-domain`,
            { domain: frontEndDomain } // Assuming API expects an array in "domains"
          );
      
          toast.success(response.data.message, {
            position: 'top-right',
            autoClose: 3000,
          });
        } catch (error: any) {
          if (error.response) {
            const errorMessage = error.response.data.message;
            toast.error(
              <div dangerouslySetInnerHTML={{ __html: errorMessage }} />,
              {
                position: 'top-right',
                autoClose: 6000,
              }
            );
          } else {
            toast.error('An unexpected error occurred.', {
              position: 'top-right',
              autoClose: 3000,
            });
          }
        }
      };
    return (
        <div className='card mb-5 mb-xl-10'>
<div className='card-header border-0'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder fs-3 mb-1'>Subscribe an Application - {application.name} - {organisation.name}</span>
        </h3>
        <div
            className='card-toolbar'
            data-bs-toggle='tooltip'
            data-bs-placement='top'
            data-bs-trigger='hover'
          >
        <button
                        data-toggle="tooltip" data-placement="bottom" title="Navigate to List of Applications"
                        className='btn btn-sm btn-light-dark fs-5'
                        onClick={() => navigate('/organisations/applications')}
                    >
                        Return to Applications List
                    </button>
                    </div>
      </div>
            {
                loading ?(
                    <div className="text-center">
                    <div className="spinner-border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                    <p>Please wait, application is being added...</p>
                  </div>
                ):(
                    <>
                    <div id='kt_account_profile_details' className='collapse show'>
                <form onSubmit={formik.handleSubmit} noValidate className='form'>
                    <div className='card-body border-top p-9'>
                        {/* commenting for future use  */}
                        {/* <div className='row mb-6'>
                            <label className='col-lg-4 col-form-label required fw-bold fs-6'>Document Encryption Variable</label>
                            <div className='col-lg-8 fv-row'>
                                <input
                                    type='text'
                                    className='form-control form-control-lg form-control-solid'
                                    placeholder="Document Encryption Variable"
                                    {...formik.getFieldProps('doc_encryption_variable')}
                                />
                                {formik.touched.doc_encryption_variable && formik.errors.doc_encryption_variable && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>{formik.errors.doc_encryption_variable}</div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className='row mb-6'>
                            <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                <span className='required'>Spaces Folder</span>
                            </label>
                            <div className='col-lg-8 fv-row'>
                                <input
                                    type='input'
                                    className='form-control form-control-lg form-control-solid'
                                    placeholder="Spaces Folder"
                                    {...formik.getFieldProps('spaces_folder')}
                                />
                                {formik.touched.spaces_folder && formik.errors.spaces_folder && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>{formik.errors.spaces_folder}</div>
                                    </div>
                                )}
                            </div>
                        </div> */}
                        <label className='col-lg-4 col-form-label fw-bold fs-6'>Domains</label>
                        <div className='border border-primary rounded p-3 px-5 m-6'>
                            <div>
                                {/* Frontend Domain */}
                                <div className='row mb-6'>
                                    <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                                        Frontend Domain 1
                                    </label>
                                    <div className='col-lg-6 fv-row'>
                                        <div style={{ position: 'relative', display: 'inline-block', width: '100%' }}>
                                            <input
                                                type="text"
                                                className="form-control form-control-lg form-control-solid"
                                                placeholder="Frontend Domain"
                                                value={formik.values.frontend_domain ? formik.values.frontend_domain[0].replace(`.${application.website_url}`, '') : ''}
                                                onChange={(e) => {
                                                    const userInput = e.target.value?.replace(`.${application.website_url}`, ''); // Remove suffix if present
                                                    const valueWithSuffix = `${userInput}.${application.website_url}`;
                                                    const apiValueWithSuffix = `api.${userInput}.${application.api_url}`;
                                                    setFrontEndDomain(valueWithSuffix);
                                                    formik.setFieldValue(`frontend_domain[0]`, valueWithSuffix);
                                                    formik.setFieldValue(`domain_name[0]`, apiValueWithSuffix);
                                                }}
                                            />
                                            {/* Suffix displayed next to the input */}
                                            <span
                                                style={{
                                                    position: 'absolute',
                                                    right: '1em',
                                                    top: '50%',
                                                    transform: 'translateY(-50%)',
                                                    pointerEvents: 'none',
                                                }}
                                            >
                                                .{application.website_url}
                                            </span>
                                        </div>
                                    </div>

                                    <div className='col-lg-2'>
                                        <button
                                            className='btn btn-info'
                                            type='button'
                                            onClick={handleDomainVerify}
                                        >
                                            Verify Domain Name
                                        </button>
                                    </div>
                                </div>

                                {/* API Domain */}
                                <div className='row mb-6'>
                                    <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                                        API Domain 1
                                    </label>
                                    <div className='col-lg-6 fv-row'>
                                        <div style={{ position: 'relative', display: 'inline-block', width: '100%' }}>
                                            <input
                                                type="text"
                                                className="form-control form-control-lg form-control-solid"
                                                placeholder="API Domain"
                                                value={formik.values.domain_name ? formik.values.domain_name[0]?.replace(`.${application.api_url}`, '') : ''}
                                                readOnly
                                            />
                                            {/* Suffix displayed next to the input */}
                                            <span
                                                style={{
                                                    position: 'absolute',
                                                    right: '1em',
                                                    top: '50%',
                                                    transform: 'translateY(-50%)',
                                                    pointerEvents: 'none',
                                                }}
                                            >
                                                .{application.api_url}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                {/* Frontend Domain */}
                                <div className='row mb-6'>
                                    <div  className=' d-flex col-lg-4'>
                                    <label className=' col-form-label fw-bold fs-6'>
                                        Frontend Domain 2
                                    </label>
                                    </div>
                                    <div className='col-lg-6 fv-row'>
                                        <div style={{ position: 'relative', display: 'flex', alignItems: 'center', width: '100%' }}>
                                            <span
                                                style={{
                                                    position: 'absolute',
                                                    left: '0.75em',
                                                    fontSize: '1rem',
                                                    pointerEvents: 'none',
                                                    color: '#6c757d',
                                                }}
                                            >
                                                {applcationDataSingle?.data?.frontend_domain_prefix?applcationDataSingle?.data?.frontend_domain_prefix:"office"}.
                                            </span>
                                            <input
                                                type="text"
                                                placeholder="Frontend Domain"
                                                className="form-control form-control-lg form-control-solid"
                                                style={{ paddingLeft: '8.5em' }}
                                                onChange={(e) => {
                                                    const userInput = e.target.value;
                                                    const valueWithSuffix = `${applcationDataSingle?.data?.frontend_domain_prefix}.${userInput}`;
                                                    formik.setFieldValue(`frontend_domain[1]`, valueWithSuffix);
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                {/* API Domain */}
                                <div className='row mb-6'>
                                    <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                        API Domain 2
                                    </label>
                                    <div className='col-lg-6 fv-row'>
                                        <div style={{ position: 'relative', display: 'flex', alignItems: 'center', width: '100%' }}>
                                            <span
                                                style={{
                                                    position: 'absolute',
                                                    left: '0.75em',
                                                    fontSize: '1rem',
                                                    pointerEvents: 'none',
                                                    color: '#6c757d',
                                                }}
                                            >
                                            {applcationDataSingle?.data?.frontend_domain_prefix?applcationDataSingle?.data?.api_domain_prefix:"office"}.
                                            </span>
                                            <input
                                                type="text"
                                                className="form-control form-control-lg form-control-solid"
                                                style={{ paddingLeft: '8.5em' }}
                                                placeholder="API Domain"
                                                onChange={(e) => {
                                                    const userInput = e.target.value;
                                                    const apiValueWithSuffix = `${applcationDataSingle?.data?.api_domain_prefix}.${userInput}`;
                                                    formik.setFieldValue(`domain_name[1]`, apiValueWithSuffix);

                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Alert variant="danger">
                                <p>
                                    The first combination of the domain is compulsory and after adding it, please contact our team to set up this sub-domain for you.
                                    Our devops department will provide the information of the DNS details which will be used to point out your sub-domains.
                                </p>
                            </Alert>
                        </div>
                        <label className='col-lg-4 col-form-label fw-bold fs-6'>IP Addresses</label>
                        <div className='border border-danger rounded p-3 px-5 m-6'>
                            {formik.values.ip_address.map((ipAddress, index) => (
                                <div className='row mb-6' key={index}>
                                    <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                        IP Address {index + 1}
                                    </label>
                                    <div className='col-lg-6 fv-row'>
                                        <input
                                            type='text'
                                            className='form-control form-control-lg form-control-solid'
                                            placeholder="IP Address"
                                            {...formik.getFieldProps(`ip_address[${index}]`)}
                                        />
                                    </div>
                                    <div className='col-lg-2 fv-row'>
                                        {formik.values.ip_address.length > 1 && (
                                            <button
                                                className='btn btn-danger'
                                                type='button'
                                                onClick={() => handleRemoveIP(index)}
                                            >
                                                Remove
                                            </button>
                                        )}
                                    </div>
                                </div>
                            ))}

                            <div className='col-lg-2'>
                                <button
                                    className='btn btn-success'
                                    type='button'
                                    onClick={handleAddIP}
                                >
                                    Add IP Address
                                </button>
                            </div>


                        </div>
                    </div>
                    <div className='card-footer d-flex justify-content-end py-6 px-9'>
                        <button type='submit' className='btn btn-primary'   disabled={ formik.values.frontend_domain[0].startsWith('.multitenant') || !formik.isValid || !formik.dirty || loading}                        >
                            {!loading && 'Submit'}
                            {loading && (
                                <span className='indicator-progress' style={{ display: 'block' }}>
                                    Please wait...{' '}
                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                            )}
                        </button>
                    </div>
                </form>
            </div >
                    </>
                )
            }
        </div >
    )
}

export default AddApplication