import axios from "axios";
import { config_multipart } from "../../../pages/api-helper/api-header-configs";
const baseURL = process.env.REACT_APP_API_URL;

// Get Single User Profile
export const get_user_profile = async () => {
    let response = await axios.get(baseURL + "/get-user-profile/");
    return response.data;
  }
  export const update_profile = async (formData:object) => {
    const response = await axios.post(
      `${baseURL}/update-user-profile/`,
      formData, // Send formData as the request body
      config_multipart
    );
  return response.data;
  }