import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { resetPassword } from '../core/_requests';
import { useNavigate } from 'react-router-dom';

function SetPassword() {
    const [userEmail, setUserEmail] = useState('');
    const [token, setToken] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const email = params.get('user_email');
        const tokenParam = params.get('token');
        if (email) setUserEmail(decodeURIComponent(email));
        if (tokenParam) setToken(decodeURIComponent(tokenParam));
    }, []);

    const validationSchema = Yup.object({
        password: Yup.string()
            .min(3, 'Minimum 3 symbols')
            .max(50, 'Maximum 50 symbols')
            .required('Password is required')
            .matches(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{3,50}$/,
                'Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character'
            ),
        confirm_password: Yup.string()
            .oneOf([Yup.ref('password')], 'Passwords must match')
            .required('Confirm Password is required')
    });

    const formik = useFormik({
        initialValues: { password: '', confirm_password: '' },
        validationSchema,
        onSubmit: async (values) => {
            setLoading(true);
            try {
                await resetPassword(token, userEmail, values.password, values.confirm_password);
                toast.success('Password set successfully', { position: 'top-right', autoClose: 3000 });
                navigate('/auth');
            } catch (error:any) {
                if (error.response) {
                    toast.error(error.response.data.message, { position: 'top-right', autoClose: 2000 });
                }
            } finally {
                setLoading(false);
            }
        }
    });

    return (
        <div className='p-5'>
            <form onSubmit={formik.handleSubmit}>
                <div className='d-flex justify-content-center'>
                    <div className='col-md-12'>
                        <h1 className='text-center pt-5 pb-5'>Set Password</h1>
                        <div className='mx-5'>
                            <div className='mb-4'>
                                <label className='col-form-label fw-bold fs-6'>Your Email</label>
                                <input
                                    type='text'
                                    className='form-control bg-transparent'
                                    value={userEmail}
                                    disabled
                                />
                            </div>
                            <div className='mb-4'>
                                <label className='form-label fw-bold fs-6 required'>Password</label>
                                <input
                                    type='password'
                                    name='password'
                                    className={`form-control bg-transparent ${formik.touched.password && formik.errors.password ? 'is-invalid' : ''}`}
                                    placeholder='Enter Password'
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.password}
                                />
                                {formik.touched.password && formik.errors.password && (
                                    <div className='text-danger'>{formik.errors.password}</div>
                                )}
                            </div>
                            <div className='mb-4'>
                                <label className='col-form-label fw-bold fs-6 required'>Confirm Password</label>
                                <input
                                    type='password'
                                    name='confirm_password'
                                    className={`form-control bg-transparent ${formik.touched.confirm_password && formik.errors.confirm_password ? 'is-invalid' : ''}`}
                                    placeholder='Enter Confirm Password'
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.confirm_password}
                                />
                                {formik.touched.confirm_password && formik.errors.confirm_password && (
                                    <div className='text-danger'>{formik.errors.confirm_password}</div>
                                )}
                            </div>
                        </div>
                        <div className='text-end mx-5 mt-4'>
                            <button type='submit' className='w-100 btn btn-light-dark' disabled={loading}>
                                {!loading ? 'Save Changes' : (
                                    <span className='indicator-progress d-block'>
                                        Please wait...
                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                    </span>
                                )}
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
}

export default SetPassword;
