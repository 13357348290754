export interface IProfileDetails {
  avatar: string
  fName: string
  lName: string
  company: string
  contactPhone: string
  companySite: string
  country: string
  language: string
  timeZone: string
  currency: string
  communications: {
    email: boolean
    phone: boolean
  }
  allowMarketing: boolean
}

export interface IUpdateEmail {
  newEmail: string
  confirmPassword: string
}

export interface IUpdatePassword {
  currentPassword: string
  newPassword: string
  passwordConfirmation: string
}

export interface IConnectedAccounts {
  google: boolean
  github: boolean
  stack: boolean
}

export interface IEmailPreferences {
  successfulPayments: boolean
  payouts: boolean
  freeCollections: boolean
  customerPaymentDispute: boolean
  refundAlert: boolean
  invoicePayments: boolean
  webhookAPIEndpoints: boolean
}

export interface INotifications {
  notifications: {
    email: boolean
    phone: boolean
  }
  billingUpdates: {
    email: boolean
    phone: boolean
  }
  newTeamMembers: {
    email: boolean
    phone: boolean
  }
  completeProjects: {
    email: boolean
    phone: boolean
  }
  newsletters: {
    email: boolean
    phone: boolean
  }
}

export interface IDeactivateAccount {
  confirm: boolean
}
export interface AddOrganisationInterface {
  name: string
  email: string
  phone: string
  mobile: string
  address_1: string
  address_2: string
  town: string
  city: string
  country: string
  postcode: string
  status: number
  applications: number[]
}

export const addOrganisationInitialValues: AddOrganisationInterface = {
  name: '',
  email: '',
  phone: '',
  mobile: '',
  address_1: '',
  address_2: '',
  town: '',
  city: '',
  country: '',
  postcode: '',
  status: 1,
  applications: []
}
export interface OrganisationListApplicationInterface {
  id: number;
  is_active: number;
  name: string;
  email: string;
  phone: string;
  mobile: string;
  address_1: string;
  address_2: string;
  town: string;
  city: string;
  country_id: number;
  country_name: string;
  post_code: string;
  color_logo?: string;
  white_logo?: string;
  icon?: string;
  status: number;
  description: string;
  website_url: string;
  api_url: string;
  logo: string;
  application_organization_id: number;
  is_tenant_created: number;
  is_superAdmin: number | boolean | string;
}

export interface OrganisationListInterface {
  is_superAdmin: number;
  id: number
  name: string
  email: string
  phone: string
  organization:{
    country:string
  }
  mobile: string
  address_1: string
  address_2: string
  town: string
  city: string
  country: string
  country_name: string
  post_code: string
  color_logo: string | undefined
  white_logo: string | undefined
  preloader: string | undefined
  favicon: string | undefined
  status: number
  applications: {
    id: number
    name: string
    description: string
    website_url: string
    api_url: string
    logo: string
    application_organization_id: number
    is_tenant_created: number
  }[]
}
export interface SubscribApplicationInterface {
  name: string
  spaces_folder: string
  organization_id: string
  user_id: string
  doc_encryption_variable: string
  domain_name: string[]
  frontend_domain: string[]
  ip_address: string[]
}

export const subscribeApplicationInitialValues: SubscribApplicationInterface = {
  name: '',
  spaces_folder: '',
  organization_id: '',
  doc_encryption_variable: '',
  user_id: '',
  domain_name: [''],
  frontend_domain: [''],
  ip_address: ['']
}
export interface SubscribedApplicationInterface {
  id: string
  name: string
  frontend_domain_prefix:string
  api_domain_prefix:string
  website_url:string
  spaces_folder: string
  organization_id: string
  user_id: string
  doc_encryption_variable: string
  domain_name: string[]
  frontend_domain: string[]
  ip_address: string[]
  updated_by: string
}
export interface OrganisationUsersInterface {
  id: string
  user: {
    id: string
    email: string
    first_name: string
    middle_name: string
    last_name: string
    mobile_no: string
    profile_picture: string
  }
  user_id: string
  organization_id: string
  can_manage_organization: number
  can_manage_application: number
}
export interface applicationsInterface {
  id: number,
  name: string,
  description: string,
  website_url: string,
  api_url: string,
  logo: string,
  is_tenant_created: number
}
export interface countryInterface {
  id: number,
  country_name: string,
  country_code: string,
}
export interface AddRolesInterface  {
  name: string
  status: number
  permissions: string[]
};
export interface RoleListInterface{
  id: number
  name: string | undefined
  status: number | undefined
  updated_by_name: string
  updated_at: string
};
export interface PermissionsInterface {
  permission_key: string
  title: string
  create: string
  view: string
  update: string
  delete: string
  guard_name: string
};
export const profileDetailsInitValues: IProfileDetails = {
  avatar: '/media/avatars/300-1.jpg',
  fName: 'Max',
  lName: 'Smith',
  company: 'Keenthemes',
  contactPhone: '044 3276 454 935',
  companySite: 'keenthemes.com',
  country: '',
  language: '',
  timeZone: '',
  currency: '',
  communications: {
    email: false,
    phone: false,
  },
  allowMarketing: false,
}

export const updateEmail: IUpdateEmail = {
  newEmail: 'support@keenthemes.com',
  confirmPassword: '',
}

export const updatePassword: IUpdatePassword = {
  currentPassword: '',
  newPassword: '',
  passwordConfirmation: '',
}

export const connectedAccounts: IConnectedAccounts = {
  google: true,
  github: true,
  stack: false,
}

export const emailPreferences: IEmailPreferences = {
  successfulPayments: false,
  payouts: true,
  freeCollections: false,
  customerPaymentDispute: true,
  refundAlert: false,
  invoicePayments: true,
  webhookAPIEndpoints: false,
}

export const notifications: INotifications = {
  notifications: {
    email: true,
    phone: true,
  },
  billingUpdates: {
    email: true,
    phone: true,
  },
  newTeamMembers: {
    email: true,
    phone: false,
  },
  completeProjects: {
    email: false,
    phone: true,
  },
  newsletters: {
    email: false,
    phone: false,
  },
}

export const deactivateAccount: IDeactivateAccount = {
  confirm: false,
}
