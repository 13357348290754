/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect} from 'react'
import {Outlet, Link} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import "../../../_metronic/assets/keenicons/duotone/style.css"
const AuthLayout = () => {
  useEffect(() => {
    const root = document.getElementById('root')
    if (root) {
      root.style.height = '100%'
    }
    return () => {
      if (root) {
        root.style.height = 'auto'
      }
    }
  }, [])

  return (
    <div className='d-flex flex-column flex-lg-row flex-column-fluid h-100 '>
      {/* begin::Body */}
      <div className='d-flex flex-column flex-lg-row-fluid w-lg-50 p-10 order-2 order-lg-1 laptop-responsive-style' >
        {/* begin::Form */}
        <div className='d-flex flex-center flex-column flex-lg-row-fluid'>
          {/* begin::Wrapper */}
          <div className='w-600px p-10'>
            <Outlet />
          </div>
          {/* end::Wrapper */}
        </div>
        {/* end::Form */}
      </div>
      {/* end::Body */}

      {/* begin::Aside */}
      <div
        className='d-flex flex-lg-row-fluid w-lg-50 bgi-size-cover bgi-position-center order-1 order-lg-2'
        style={{backgroundImage: `url(${toAbsoluteUrl('/media/misc/auth-bg.png')})`}}
      >
        {/* begin::Content */}
        <div className='d-flex flex-column flex-center py-15 px-5 px-md-15 w-100'>
          {/* begin::Logo */}
         <div className='image-container'>
    <img
      alt='Wriko Central'
      src={toAbsoluteUrl('/media/logos/wriko_central_white_logo.png')}
      className='h-40px mb-3'
      onError={(e:any) => {
        e.target.style.display = 'none'; // Hide the broken image
        e.target.nextElementSibling.style.display = 'block'; // Show the fallback text
      }}
    />
    <span
      className='auth-img-alt'
    >
      Wriko Central
    </span>
</div>

          {/* end::Logo */}

          {/* begin::Image */}
          <img
            className={`mx-auto w-275px w-md-50 w-xl-300px mb-10 mb-lg-20 `}
            src={toAbsoluteUrl('/media/logos/wriko_central_icon.png')}
            alt='Wriko Central'
            onError={(e:any) => {
              e.target.style.display = 'none'; // Hide the broken image
              e.target.nextElementSibling.style.display = 'block'; // Show the fallback text
            }}
          />
          {/* end::Image */}

          {/* begin::Title */}
          <h1 className='text-white fs-2qx fw-bolder text-center mb-7'>
          Welcome to Wriko Central
          </h1>
          {/* end::Title */}

          {/* begin::Text */}
          <div className='text-white fs-base text-center'>
          Wriko Central is your all-in-one platform for managing organizational workflows and applications across multiple departments. With a secure single sign-on system, streamline your operations and boost efficiency, all from one central hub.
          </div>
          {/* end::Text */}
        </div>
        {/* end::Content */}
      </div>
      {/* end::Aside */}
    </div>
  )
}

export {AuthLayout}
