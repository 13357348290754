import React, { useEffect, useState } from 'react'
import { OrganisationListApplicationInterface, OrganisationListInterface, applicationsInterface } from '../../../modules/accounts/components/settings/SettingsModel'
import { useLocation, useNavigate } from 'react-router-dom'
import { Card } from 'react-bootstrap'
import { KTIcon, KTSVG, toAbsoluteUrl } from '../../../../_metronic/helpers'
import { useQuery } from 'react-query'
import { get_all_applications, get_organisation_tenant, get_single_user_organisations_all_application } from '../api/applicationsApi'
import { get_organisation_by_user } from '../../organisations/api/OrganisationsApi'
import { toast } from 'react-toastify'
// interface User {
//     access_token: string;
// }
function ApplicationsList() {
    const [organizationName, setOrganizationName] = useState('');
    const [isWindowOpening, setIsWindowOpening] = useState(false); // State for loading indicator
    const [NewApplicationAdded, setNewApplicationAdded] = useState(false);
    const [applicationList, setApplicationList] = useState<applicationsInterface[]>([]);
    const [selectedOrganisation, setSelectedOrganisation] = useState<OrganisationListApplicationInterface[]>([]);
    const [selectedApplication, setSelectedApplication] = useState<applicationsInterface>();
    const [applicationsArray, setApplicationsArray] = useState<number[]>([]);
    const current_organisation_id=localStorage.getItem("current_organisation_id");
    const authData = JSON.parse(localStorage.getItem('kt-auth-react-v') || '{}');
    const location = useLocation();
    const current_user_id=authData.user.id;
    // State to manage the flag
    const [canManageApplication, setCanManageApplication] = useState(false);
    const userId: string = JSON.parse(localStorage.getItem('kt-auth-react-v') || '')?.user.id || null;
    // const iframeRef = useRef<HTMLIFrameElement | null>(null);
    const organisation = (location.state as { organisation: OrganisationListInterface })?.organisation || { selectedOrganisation}; 
    const { data:dataOrganisation, isLoading,refetch:dataOrganisationRefetch } = useQuery(['get_single_user_organisations_all_application',localStorage.getItem("current_organisation_id"),{         refetchOnMount: true, 
        refetchOnWindowFocus: true,  }], () =>
        get_single_user_organisations_all_application(localStorage.getItem("current_organisation_id"))
    );
    const { data:dataSingleUser,refetch:dataSingleUserRefetch } = useQuery(['get_organisation_by_user',current_user_id,{ refetchOnMount: true, 
        refetchOnWindowFocus: true }], () =>
        get_organisation_by_user(current_user_id)
    );
    useEffect(() => {
        if (dataSingleUser?.data && current_organisation_id) {
          const hasAccess = dataSingleUser.data.some((org) => {
            return (
              org.organization.id == current_organisation_id && org.can_manage_application === 1
            );
          });
          setCanManageApplication(hasAccess);
        }
      }, [dataSingleUser, organisation]);
    // console.log("dataSingleUser",dataSingleUser.data.can_manage_application)
    const navigate = useNavigate();
    useEffect(() => {
        // Fetch the organization name from localStorage
        const name:any= localStorage.getItem('current_organisation_name');
        setOrganizationName(name);
    }, []);
    useEffect(() => {
        if (dataOrganisation) {
            setSelectedOrganisation(dataOrganisation.data);
        }
    }, [dataOrganisation]);
    useEffect(() => {
        dataOrganisationRefetch();
        setNewApplicationAdded(true)
    }, [NewApplicationAdded,dataOrganisationRefetch])
    
    const { data: applicationData } = useQuery('applications', get_all_applications, {
        refetchOnMount: true, 
        refetchOnWindowFocus: true, 
    });
    useEffect(() => {
        if (selectedOrganisation) {
            const inactiveAppIds = selectedOrganisation
                .filter((app) => app.is_active === 0)
                .map((app) => app.id);
            localStorage.setItem("all_applications_id", JSON.stringify(inactiveAppIds));
        }
    }, [selectedOrganisation]);    
    useEffect(() => {
        if (applicationData) {
            setApplicationList(applicationData.data as applicationsInterface[]);
        }
    }, [applicationData]);

    
    let myCustomArray: typeof applicationList = []; // to store the filtered applications
    
    applicationList.forEach((list) => {
        // Check if the application id does not match any selectedOrganisation id
        const isMatching = selectedOrganisation.some(org => org.id === list.id);
    
        // If there is no match, push it to the custom array
        if (!isMatching) {
            myCustomArray.push(list);
        }else{
                applicationsArray.push(list.id)
        }
    });
    
    // const handleAddApplication = useMutation(add_application_in_organisation, {
    //     onSettled: (data, error) => {
    //         if (!error) {
    //             toast.success(data.message, {
    //                 position: 'top-right',
    //                 autoClose: 3000,
    //             });
    //             dataOrganisationRefetch();
    //         } else {
    //             toast.error('An error occurred. Please try again later.', {
    //                 position: 'top-right',
    //                 autoClose: 3000,
    //             });
    //         }
    //     },
    // });
    const handleSelectApplication = (applicationId) => {
        let updatedApplications;
        if (applicationsArray.includes(applicationId)) {
            updatedApplications = applicationsArray.filter(item => item !== applicationId);
        } else {
            updatedApplications = [...applicationsArray, applicationId];
        }
        setApplicationsArray(updatedApplications);
    };
    // const handleAddApplications = () => {
    //     const arr = applicationsArray;
    //     const uniqueArr = arr.filter((value, index, self) => self.indexOf(value) === index); 
    //     if (applicationsArray && applicationsArray.length > 0) {
    //         // Post the selected applications data here
    //         handleAddApplication.mutate({
    //             organisation_id: localStorage.getItem("current_organsation_id") || organisation.id,
    //             applications: applicationsArray
    //         });
    //     }
    //     dataOrganisationRefetch();
    // };
    if (isLoading) {
        return (
            <div className='text-center'>
            <div className='spinner-border' role='status'>
              <span className='visually-hidden'>Loading...</span>
            </div>
          </div>
        );
      }
    const handleSubscribeApplication = (organisation: any, application: any) => {
    // Combine `application.id` and `applicationsArray` into a single array
    const combinedIds = [...applicationsArray, application.id];
    // Ensure the array is unique
    const uniqueCombinedIds = Array.from(new Set(combinedIds));
    // Save the combined array in localStorage
    localStorage.setItem("all_application_ids", JSON.stringify(uniqueCombinedIds));
        navigate("/organisations/applications/subscribe-new-application", { state: { organisation: organisation, application: application } });
    };
    const handleApplicationView = async (application) => {
        try {
            setIsWindowOpening(true);
    
            const response = await get_organisation_tenant(
                localStorage.getItem("current_organisation_id"),
                userId,
                application.api_url
            );
    
            const frontendDomain = response?.data?.[0]?.frontend_domain?.[0];
            if (frontendDomain) {
                const fullUrl = `https://${frontendDomain}`;
                window.open(fullUrl, "_blank");
            } else {
                // Backend response message (if any) is shown in toast
                const backendMessage = response?.message || "Frontend domain not found.";
                console.error("Frontend domain not found in the response.");
                toast.error(backendMessage, {
                    position: "top-right",
                    autoClose: 4000,
                });
            }
        } catch (error:any) {
            // Display backend error message if available
            const errorMessage =
                error?.response?.data?.message || "You do not have permission to view this application.";
            console.error("Error fetching organisation tenant:", error);
            toast.error(errorMessage, {
                position: "top-right",
                autoClose: 4000,
            });
        } finally {
            setIsWindowOpening(false);
        }
    };
    
    
    const handleApplicationEdit = (organisation, application) => {
        navigate("/organisations/applications/edit-application", {
            state: { organisation, application },
        });
    };    
    const storedTheme = localStorage.getItem('kt_theme_mode_value');
    return (
        <>
            <Card className="mb-3 shadow-sm rounded">
                {/* Card Header with Organization Name */}
                <div className="card-header text-center border-0 pt-4 pb-4 bg-light">
                    <h3 className="card-title fw-bold text-primary m-0">
                        Organisation: {organisation?.name || organizationName}
                    </h3>
                    <button
                        data-toggle="tooltip" data-placement="bottom" title="Navigate to List of Organisations"
                        className='btn btn-sm btn-light-dark fs-5'
                        onClick={() => navigate('/organisations/organisations-list')}
                    >
                        Return to Organisations List
                    </button>
                </div>
            </Card>
            {selectedOrganisation ? (
                <>
                    <Card className='mb-3'>
                        <Card.Header>
                            <div className='card-title m-0'>
                                <h3 className='fw-bolder m-0'>My Apps </h3>
                            </div>
                        </Card.Header>
                        <Card.Body className="row">
                              {/* Show Loading Indicator */}
         {isWindowOpening && (
         <div className='text-center'>
         <div className='spinner-border text-primary mb-3' role='status'>
             <span className='visually-hidden'>Loading...</span>
         </div>
         <p className='text-muted'>Please wait while we open your application...</p>
     </div>     
        )}
    {/* Section for Active Apps */}
    <div className="col-12 mb-5">
    <h5 className="text-primary">Active Apps</h5>
<div className="row">
    {Array.isArray(selectedOrganisation) &&
        selectedOrganisation.filter((application) => application.is_active === 1).length > 0 ? (
        selectedOrganisation
            .filter((application) => application.is_active === 1)
            .map((application) => (
                <Card
                    key={application.id}
                    className={`col-lg-3 col-md-5 application-card-list ms-3 mt-2`}
                    onClick={(e) => {
                            handleApplicationView(application);
                        e.stopPropagation();
                    }}
                >
                    {/* <div className="position-relative">
                        <div
                            className="btn btn-icon btn-sm btn-active-light-primary position-absolute top-0 end-0 mt-2"
                            data-bs-toggle="modal"
                            data-bs-target="#kt_modal_1"
                            onClick={(e) => {
                                e.stopPropagation();
                                setSelectedApplication(application);
                            }}
                        >
                            <KTSVG
                                path="/media/icons/duotune/arrows/arr061.svg"
                                className="svg-icon svg-icon-2x"
                            />
                        </div>
                    </div> */}
                    <Card.Body>
                        <Card.Text>
                            <div className="d-flex justify-content-center mt-9">
                                {application.logo === null ? (
                                    <img
                                        alt="Logo"
                                        src={
                                            storedTheme === "dark"
                                                ? toAbsoluteUrl("/media/logos/wriko_central_white_icon.png")
                                                : toAbsoluteUrl("/media/logos/wriko_central_icon.png")
                                        }
                                        className="h-25px"
                                    />
                                ) : (
                                    <img
                                        alt="Logo"
                                        src={application.logo}
                                        className="h-25px app-sidebar-logo-default theme-light-show"
                                    />
                                )}
                            </div>
                        </Card.Text>
                        <Card.Title className="d-flex justify-content-center text-primary cursor-pointer fs-8 text-dark">
                            {application.name}
                        </Card.Title>
                        {canManageApplication && (
                            <div className="d-none justify-content-center mt-auto application-setting-button">
                                <button
                                    data-toggle="tooltip"
                                    data-placement="bottom"
                                    title="Edit Subscribed Application"
                                    className="btn btn-icon btn-sm btn-active-secondary"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleApplicationEdit(
                                            organisation,
                                            application
                                        );
                                    }}

                                >
                                    <KTIcon
                                        iconName="setting-2"
                                        className="fs-3 text-dark"
                                    />
                                </button>
                            </div>
                        )} 
                    </Card.Body>
                </Card>
            ))
    ) : (
        <div className="col-12 text-center mt-5">
            <h6 className="text-muted">No active apps available</h6>
        </div>
    )}
</div>

    </div>
 {/* Separator Line */}
 <hr className="my-4" />
    {/* Section for Inactive Apps */}
    <div className="col-12">
        <h5 className="text-danger">Inactive Apps</h5>
        <div className="row">
        {Array.isArray(selectedOrganisation) &&
        selectedOrganisation.filter((application) => application.is_active === 0).length > 0 ? (
        selectedOrganisation
            .filter((application) => application.is_active === 0)
            .map((application) => (
                        <Card
                            key={application.id}
                            className={`col-lg-3 col-md-5 application-card-list ms-3 mt-2`}
                        >
                            {/* <div className="position-relative">
                                <div
                                    className="btn btn-icon btn-sm btn-active-light-primary position-absolute top-0 end-0 mt-2"
                                    data-bs-toggle="modal"
                                    data-bs-target="#kt_modal_1"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setSelectedApplication(application);
                                    }}
                                >
                                    <KTSVG
                                        path="/media/icons/duotune/arrows/arr061.svg"
                                        className="svg-icon svg-icon-2x"
                                    />
                                </div>
                            </div> */}
                            <Card.Body>
                                <Card.Text>
                                    <div className="d-flex justify-content-center mt-9">
                                        {application.logo === null ? (
                                            <img
                                                alt="Logo"
                                                src={
                                                    storedTheme === "dark"
                                                        ? toAbsoluteUrl("/media/logos/wriko_central_white_icon.png")
                                                        : toAbsoluteUrl("/media/logos/wriko_central_icon.png")
                                                }
                                                className="h-25px"
                                            />
                                        ) : (
                                            <img
                                                alt="Logo"
                                                src={application.logo}
                                                className="h-25px app-sidebar-logo-default theme-light-show"
                                            />
                                        )}
                                    </div>
                                </Card.Text>
                                <Card.Title className="d-flex justify-content-center text-primary cursor-pointer fs-8 text-dark">
                                    {application.name}
                                </Card.Title>
                                {organisation.is_superAdmin ||
                                selectedOrganisation[0].is_superAdmin ? (
                                    <div className="d-none justify-content-center mt-auto application-setting-button">
                                        <button
                                            data-toggle="tooltip"
                                            data-placement="bottom"
                                            title="Edit Subscribed Application"
                                            className="btn btn-icon btn-sm btn-active-secondary"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                handleApplicationEdit(
                                                    organisation,
                                                    application
                                                );
                                            }}
                                        >
                                            <KTIcon
                                                iconName="setting-2"
                                                className="fs-3 text-dark"
                                            />
                                        </button>
                                    </div>
                                ) : null}
                            </Card.Body>
                        </Card>
                      ))
                    ) : (
                        <div className="col-12 text-center mt-5">
                            <h6 className="text-muted">No inactive apps available</h6>
                        </div>
                    )}
        </div>
    </div>
</Card.Body>
                    </Card>

                    <Card className='mb-3'>
                        <Card.Header>
                            <div className='card-title m-0'>
                                <h3 className='fw-bolder m-0'>More Apps</h3>
                            </div>
                        </Card.Header>
                        <Card.Body className='row'>
                            {Array.isArray(myCustomArray) && myCustomArray.length > 0 ? (
                                myCustomArray.map((application) => (
                                    <React.Fragment key={application.id}>
                                        <Card className="col-lg-3 col-md-5 application-card-list ms-3 mt-2" title="Subscribe an Application"
                                            onClick={() => {
                                                if (canManageApplication) {
                                                    handleSubscribeApplication(selectedOrganisation, application);
                                                    handleSelectApplication(application.id);
                                                }
                                            }}
                                            style={{
                                                cursor: canManageApplication ? "pointer" : "not-allowed",
                                                pointerEvents: canManageApplication ? "auto" : "none",
                                            }}>
                                            <Card.Body>
                                            <div className="d-flex justify-content-center mt-3">
                                                <img
                                                    alt="Logo"
                                                    src={
                                                        application && application.logo
                                                            ? application.logo
                                                            : storedTheme === "dark"
                                                                    ? toAbsoluteUrl("/media/logos/wriko_central_white_icon.png")
                                                                    : toAbsoluteUrl("/media/logos/wriko_central_icon.png")
                                                    }
                                                    className="h-25px "
                                                />
                                            </div>

                                            <Card.Title
                                                className="d-flex justify-content-center text-primary cursor-pointer fs-8 text-dark"
                                            >
                                                {application.name}
                                            </Card.Title>

                                            <div className="d-none justify-content-center mt-auto application-setting-button">
                                                <button
                                                    data-toggle="tooltip"
                                                    data-placement="bottom"
                                                    title="Subscribe an Application"
                                                    className="btn btn-icon btn-dark btn-active-secondary btn-sm round-button"
                                                    onClick={() => handleSubscribeApplication(selectedOrganisation, application)}
                                                >
                                                    <KTIcon iconName="plus" className="fs-3" />
                                                </button>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </React.Fragment>
                           ))
                        ) : (
                            <div className="text-center mt-4 text-muted">No apps available</div>
                        )}
                            {/* <Card className={`col-lg-3 col-md-5 application-card-list ms-3 mt-2 `}>
                                <Card.Body className='d-flex justify-content-center'>
                                    <Card.Text >
                                        <div className='d-flex justify-content-center '>
                                            <button
                                               
                                            // onClick={() => handleSubscribeApplication(selectedOrganisation)}
                                            >
                                                <KTIcon iconName='plus' className='fs-3' />
                                            </button>
                                        </div>
                                        <p className='text-muted mt-5'>
                                            Add Application
                                        </p>
                                    </Card.Text>
                                </Card.Body>
                            </Card> */}
                        </Card.Body>
                    </Card>
                </>
            ) : (
                <div>No Applications found</div>
            )}
            {/* Delete modal */}
            <div className='modal fade' tabIndex={-1} id='kt_modal_1'>
                <div className='modal-dialog'>
                    <div className='modal-content'>
                        <div className='modal-header'>
                            <h5 className='modal-title'>Unsubscribe Application</h5>
                            <div
                                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                                data-bs-dismiss='modal'
                                aria-label='Close'
                            >
                                <KTSVG
                                    path='/media/icons/duotune/arrows/arr061.svg'
                                    className='svg-icon svg-icon-2x'
                                />
                            </div>
                        </div>
                        <div className='modal-body'>
                            {selectedApplication ? (
                                <>
                                    <p className='mb-2'>
                                        <span className='fw-bold'>Application Name: </span> {selectedApplication.name}
                                    </p>
                                    <p className='mb-2'>
                                        <span className='fw-bold'>Description: </span>{' '}
                                        {selectedApplication.description}
                                    </p>
                                </>
                            ) : null}
                        </div>
                        <div className='modal-footer'>
                            <button data-toggle="tooltip" data-placement="bottom" title="close" type='button' className='btn btn-light' data-bs-dismiss='modal'>
                                Close
                            </button>
                            <button
                                data-toggle="tooltip" data-placement="bottom" title="Delete this Organisation"
                                type='button'
                                // onClick={() => {
                                //     if (selectedOrganisation && selectedOrganisation.id) {
                                //         handleDeleteOrganisation.mutate(selectedOrganisation.id)
                                //     }
                                // }}
                                className='btn btn-danger'
                                data-bs-dismiss='modal'
                            >
                                Unsubscribe
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className='modal fade' tabIndex={-1} id='kt_modal_2'>
                <div className='modal-dialog modal-lg'>
                    <div className='modal-content'>
                        <div className='modal-header'>
                            <h3 className='modal-title'>Add Application</h3>
                            <div
                                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                                data-bs-dismiss='modal'
                                aria-label='Close'
                            >
                                <KTSVG
                                    path='/media/icons/duotune/arrows/arr061.svg'
                                    className='svg-icon svg-icon-2x'
                                />
                            </div>
                        </div>
                        <div className='modal-body'>
                            <div className='row mb-6'>
                                <div className='col-lg-12 fv-row row mx-2 px-9'>
                                    {myCustomArray.map((application) => (
                                        <>
                                                                                <div>
                                                                            <label className='col-lg-12 col-form-label fw-bold fs-6'>
                                    <h4>Are you sure you want to add "{application?.name}" to your applications?</h4>
                                </label>
                                        </div>
                                        <Card className={`col-lg-4 col-md-5 application-card-list ms-5 mt-4 ${applicationsArray.includes(application.id) ? 'selected-application-card' : ''} `}
                                            onClick={() => handleSelectApplication(application.id)}
                                        >
                                            <Card.Body>
                                                <Card.Text>
                                                    <div className='d-flex justify-content-center mt-9'>
                                                        {application && application.logo === null ? (
                                                            <img
                                                                alt='Logo'
                                                                src={toAbsoluteUrl('/media/logos/wriko_central_icon.png')}
                                                                className='h-25px app-sidebar-logo-default theme-light-show'
                                                            />
                                                        ) : (
                                                            <img
                                                                alt='Logo'
                                                                src={application.logo}
                                                                className='h-25px app-sidebar-logo-default theme-light-show'
                                                            />
                                                        )}

                                                    </div>
                                                </Card.Text>
                                                <Card.Title className='d-flex justify-content-center text-primary cursor-pointer fs-7 text-dark' >{application.name} {' '}</Card.Title>
                                                <Card.Subtitle className="d-flex justify-content-center mb-2 text-muted fs-9">{application.description}</Card.Subtitle>
                                            </Card.Body>
                                        </Card>
                                        </>

                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className='modal-footer'>
                            <button data-toggle="tooltip" data-placement="bottom" title="close" type='button' className='btn btn-light' data-bs-dismiss='modal'>
                                No
                            </button>
                            <button
                                data-toggle="tooltip" data-placement="bottom" title="Add Application"
                                type='button'
                                onClick={handleAddApplications}
                                className='btn btn-primary'
                                data-bs-dismiss='modal'
                            >
                                Yes
                            </button>
                        </div>
                    </div>
                </div>
            </div> */}
        </>
    )
}

export default ApplicationsList