import React, { ChangeEvent, useEffect, useState } from 'react';
import { OrganisationListInterface, SubscribedApplicationInterface, applicationsInterface } from '../../../modules/accounts/components/settings/SettingsModel';
import { useLocation, useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';
import { edit_tenant_general_settings, get_organisation_tenant, get_single_application, update_application } from '../api/applicationsApi';
import { toast } from 'react-toastify';
import { Alert } from 'react-bootstrap';
import axios from 'axios';
function EditApplication() {
  const [selectedApplication, setSelectedApplication] = useState<SubscribedApplicationInterface | null>(null);
  const [ViewApplication, setViewApplication] = useState<SubscribedApplicationInterface | null>(null);
  const location = useLocation();
  const [frontEndDomain, setFrontEndDomain] = useState<string[]>([]); // eslint-disable-next-line 
  const organisation = (location.state as { organisation: OrganisationListInterface })?.organisation;
  const application = (location.state as { application: applicationsInterface })?.application;
  const [docEncVarError, setDocEncVarError] = useState('');
  const [spacesFolderError, setSpacesFolderError] = useState('');
  const [domains, setDomains] = useState<{ frontend: string; api: string }[]>([]);
  const [ipAddresses, setIpAddresses] = useState<string[]>([]);
  const navigate = useNavigate();
  const userId: string = JSON.parse(localStorage.getItem('kt-auth-react-v') || '')?.user.id || null;
  const [loading, setLoading] = useState(false);
    // const [applicationData, setApplicationData] = useState<applicationsInterface | null>(null);
  const { data:organisationTenantdata, isLoading,isError,error} = useQuery(
    ['get_organisation_tenant', localStorage.getItem("current_organisation_id"), userId],
    () => get_organisation_tenant(localStorage.getItem("current_organisation_id"), userId, application.api_url),
    {
      refetchOnWindowFocus: true,
      staleTime: 0, // Forces fresh data on every fetch
      cacheTime: 0, // Clears cached data immediately after usage
    }
  );
  // Get Single Application Data
  const { data:applcationDataSingle,isLoading:isLoadingApplcationDataSingle } = useQuery(
    ['get_single_application',application?.id],
    () => get_single_application( application?.id),
    {
      refetchOnWindowFocus: false,
      staleTime: 0, // Forces fresh data on every fetch
      cacheTime: 0, // Clears cached data immediately after usage
    }
  );
  useEffect(() => {
    if(applcationDataSingle){
      setViewApplication(applcationDataSingle?.data)
    }
  }, [applcationDataSingle])
  useEffect(() => {
    if(selectedApplication){
      const domainName:any=selectedApplication?.frontend_domain ? selectedApplication.frontend_domain[0] : '';
      setFrontEndDomain(domainName)
    }
  }, [selectedApplication])
  useEffect(() => {
    if (organisationTenantdata) {
      setSelectedApplication(organisationTenantdata.data[0] as SubscribedApplicationInterface);
  
      if (organisationTenantdata.data[0]) {
        const domainPairs = organisationTenantdata.data[0].frontend_domain.map((frontendDomain, index) => {
          const formattedDomain = frontendDomain.includes(ViewApplication?.website_url)
            ? frontendDomain
            : `${frontendDomain}.${ViewApplication?.website_url}`;
          return {
            frontend: formattedDomain,
            api: organisationTenantdata.data[0].domain_name[index] || '', // Ensure API domain exists
          };
        });
  
        setDomains(domainPairs);
        setIpAddresses(organisationTenantdata.data[0].ip_address); // Initialize IP addresses
      }
    }
  }, [organisationTenantdata,ViewApplication]);  
useEffect(() => {
  if (!organisationTenantdata) {
    setDomains([]); // Reset domains
    setIpAddresses([]); // Reset IP addresses
    setSelectedApplication(null);
    setViewApplication(null)
  }
}, [organisationTenantdata]);
  // const handleDocEncVarChange = (e: ChangeEvent<HTMLInputElement>) => {
  //   const newDocEncVar = e.target.value;
  //   setDocEncVarError(newDocEncVar ? '' : 'Document Encryption Variable is required.');
  //   if (selectedApplication) {
  //     setSelectedApplication({
  //       ...selectedApplication,
  //       doc_encryption_variable: newDocEncVar,
  //     });
  //   }
  // };

  const handleSpacesFolderChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newSpacesFolder = e.target.value;
    setSpacesFolderError(newSpacesFolder ? '' : 'Spaces Folder is required.');
    if (selectedApplication) {
      setSelectedApplication({
        ...selectedApplication,
        spaces_folder: newSpacesFolder,
      });
    }
  };

  const handleAddDomain = () => {
    setDomains([...domains, { frontend: '', api: '' }]);
  };
  const handleFrontendDomainChange = (e: ChangeEvent<HTMLInputElement>, index: number) => {
    const { value } = e.target;
  
    // Update the `frontend_domain` state
    setFrontEndDomain((prevDomains) => {
      const updatedDomains = [...prevDomains];
      updatedDomains[index] = value; // Update the specific domain at the given index
      return updatedDomains;
    });
  
    // Update `selectedApplication` if it's defined
    if (selectedApplication) {
      let updatedFrontendDomain = [...selectedApplication.frontend_domain];
      updatedFrontendDomain[index] = value;
  
      // Prevent duplicate suffixes
      updatedFrontendDomain = updatedFrontendDomain.map((frontendDomain, idx) => {
        if (idx === 1) return frontendDomain; // Keep as is for index 1
        if (frontendDomain.includes(applcationDataSingle?.data?.website_url)) {
          return frontendDomain; // Prevent duplicate appends
        }
        return `${frontendDomain}.${applcationDataSingle?.data?.website_url}`; // Append suffix
      });
  
      // Create domain pairs
      const domainPairs = updatedFrontendDomain.map((frontendDomain, idx) => ({
        frontend: frontendDomain,
        api: domains[idx]?.api || '',
      }));
      // Update state
      setDomains(domainPairs);
      setSelectedApplication({
        ...selectedApplication,
        frontend_domain: updatedFrontendDomain,
      });
    }
  };
  const handleAPIDomainChange = (e: ChangeEvent<HTMLInputElement>, index: number) => {
    const { value } = e.target;

    if (selectedApplication) {
      const updatedAPIDomain = [...selectedApplication.domain_name];
      updatedAPIDomain[index] = value;

      const domainPairs = updatedAPIDomain.map((domainName, idx) => ({
        frontend: domains[idx]?.frontend || '',
        api: domainName,
      }));

      // Update the states
      setDomains(domainPairs);
      setSelectedApplication({
        ...selectedApplication,
        domain_name: updatedAPIDomain,
      });
    }
  };
  const handleRemoveDomain = (index: number) => {
    const updatedDomains = domains.filter((_, i) => i !== index);
    setDomains(updatedDomains);
  };
  const handleAddIpAddress = () => {
    setIpAddresses([...ipAddresses, '']); // Add an empty string for the new IP address
  };

  const handleRemoveIpAddress = (index: number) => {
    const newIpAddresses = ipAddresses.filter((_, i) => i !== index);
    setIpAddresses(newIpAddresses); // Remove the IP address at the specified index
  };

  const handleIpAddressChange = (index: number, value: string) => {
    const newIpAddresses = [...ipAddresses];
    newIpAddresses[index] = value; // Update the specific IP address
    setIpAddresses(newIpAddresses);
    if (selectedApplication) {
      setSelectedApplication({
        ...selectedApplication,
        ip_address: newIpAddresses,
      });
    }
  };
const handleUpdateApplication = () => {
  try {
      setLoading(true);

      if (!selectedApplication) {
          throw new Error('No Application Selected for Update');
      }

      const {
          id,
          name,
          organization_id,
          user_id,
          domain_name,
          frontend_domain,
          ip_address,
      } = selectedApplication;

      // Check if `ipAddresses` state is empty
      const isIpAddress = ipAddresses.filter(ip => ip !== null).length > 0 ? '1' : '0';
      const formData = new FormData();
      formData.append('name', name || '');
      formData.append('organization_id', organization_id || '');
      formData.append('application_id', String(application.id) || '');
      formData.append('is_ip_address', isIpAddress);
      formData.append('user_id', user_id || '');

      // Construct domain_name[0] with `api.frontend_domain` and `api_url`
      if (frontend_domain && frontend_domain.length > 0) {
          const updatedDomainName = domain_name ? [...domain_name] : [];

          frontend_domain.forEach((domain, index) => {
              let updatedDomain = domain;

              // Check if the prefix already exists in domain at index 1
              if (index === 1 && !domain.startsWith(`${ViewApplication?.frontend_domain_prefix || ''}.`)) {
                  updatedDomain = `${ViewApplication?.frontend_domain_prefix || ''}.${domain}`;
              }

              // Append frontend domain to FormData
              formData.append(`frontend_domain[${index}]`, updatedDomain);

              // Handle domain_name based on frontend domain
              if (index === 0) {
                  const frontendDomainName = domain.split('.')[0] || ''; // Extract name before `.`
                  const apiDomain = `api.${frontendDomainName}`;
                  const fullDomain = `${apiDomain}.${application.api_url}`;
                  updatedDomainName[0] = fullDomain;
              }
          });

          // Append domain_name array to FormData
          updatedDomainName.forEach((domain, index) => {
              if (index === 1) {
                  if (!domain.startsWith(`${ViewApplication?.api_domain_prefix || ''}.`)) {
                      const updatedDomain = `${ViewApplication?.api_domain_prefix || ''}.${domain}`;
                      formData.append(`domain_name[${index}]`, updatedDomain);
                  } else {
                      formData.append(`domain_name[${index}]`, domain);
                  }
              } else {
                  formData.append(`domain_name[${index}]`, domain);
              }
          });
      }
      // Append IP addresses or ensure empty array if null
      if (ip_address && ip_address.length > 0 && ip_address.some(ip => ip)) {
        ip_address.forEach((ip, index) => {
          if (ip) {
            formData.append(`ip_address[${index}]`, ip); // Append only non-empty values
          }
        });
      } else {
        // Append an empty array by not specifying an index
        formData.append(`ip_address[]`, ""); // This sends an empty array in a compatible way
      }
      
      // Debug: Log FormData contents
      formData.forEach((value, key) => {
          console.log(key, value);
      });
      setLoading(false);
      // Trigger mutations (uncomment if mutations are enabled)
      updateApplicationMutation.mutate({
        id,
        formData,
      });
      editGeneralSettingsMutation.mutate({
        organization_id: organization_id,
        application_id: application.id,
        user_id: user_id,
        frontend_domain: frontend_domain[0],
        domain: domain_name[0],
      });
  } catch (error:any) {
      toast.error(error.message || 'An error occurred.', {
          position: 'top-right',
          autoClose: 3000,
      });
      setLoading(false);
  }
};

  const handleDomainVerify = async () => {
    try {
      if (frontEndDomain.length === 0) {
        toast.error('No domains provided for verification.', {
          position: 'top-right',
          autoClose: 3000,
        });
        return;
      }
  
      const response= await axios.post(
        `https://${application.api_url}/api/verify-domain-with-tenant`,
        { domain: frontEndDomain,tenant_id:selectedApplication?.id },
      );
  
      toast.success(response.data.message, {
        position: 'top-right',
        autoClose: 3000,
      });
    } catch (error: any) {
      if (error.response) {
        const errorMessage = error.response.data.message;
        toast.error(
          <div dangerouslySetInnerHTML={{ __html: errorMessage }} />,
          {
            position: 'top-right',
            autoClose: 6000,
          }
        );
      } else {
        toast.error('An unexpected error occurred.', {
          position: 'top-right',
          autoClose: 3000,
        });
      }
    }
  };

  const updateApplicationMutation = useMutation((Data: any) => update_application(Data.id, Data.formData, application.api_url), {
    onSuccess: (response) => {
      setLoading(false);
      // toast.success(response.message, {
      //   position: 'top-right',
      //   autoClose: 3000,
      // });
      navigate("/organisations/applications");
    },
    onError: (error: any) => {
      if (error.response) {
        toast.error(error.response.data.message, {
          position: "top-right",
          autoClose: 3000,
        });
        setLoading(false);
      } else if (error.request) {
        toast.error('No response received from the server.', {
          position: "top-right",
          autoClose: 3000,
        });
        setLoading(false);
      } else {
        toast.error('An unexpected error occurred.', {
          position: "top-right",
          autoClose: 3000,
        });
      }
      setLoading(false);
    },
  });
  const editGeneralSettingsMutation = useMutation(edit_tenant_general_settings, {
    onSuccess: (response) => {
      setLoading(false);
      toast.success("Organization Application Updated Successfully", {
        position: 'top-right',
        autoClose: 3000,
      });
    },
    onError: (error: any) => {
      if (error.response) {
        toast.error(error.response.data.message, {
          position: "top-right",
          autoClose: 3000,
        });
        setLoading(false);
      } else if (error.request) {
        toast.error('No response received from the server.', {
          position: "top-right",
          autoClose: 3000,
        });
        setLoading(false);
      } else {
        toast.error('An unexpected error occurred.', {
          position: "top-right",
          autoClose: 3000,
        });
      }
      setLoading(false);
    },
  });
  if (isLoadingApplcationDataSingle) {
    return (
      <div className='text-center'>
        <div className='spinner-border' role='status'>
          <span className='visually-hidden'>Loading...</span>
        </div>
      </div>
    )
  }
  return (
    <div className='card mb-5 mb-xl-10'>
      <div className='card-header border-0'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder fs-3 mb-1'>Edit Subscribed Application</span>
        </h3>
        <div
            className='card-toolbar'
            data-bs-toggle='tooltip'
            data-bs-placement='top'
            data-bs-trigger='hover'
          >
        <button
                        data-toggle="tooltip" data-placement="bottom" title="Navigate to List of Applications"
                        className='btn btn-sm btn-light-dark fs-5'
                        onClick={() =>{ 
                          navigate('/organisations/applications')
                        }
                      }
                    >
                        Return to Applications List
                    </button>
                    </div>
      </div>
      <div id='kt_account_profile_details' className='collapse show'>
        <div className='card-body border-top p-9'>
        {isError ? (
    <table>
      <tbody>
        <tr>
          <td>{(error as any)?.response?.data?.message  || "An unexpected error occurred."}</td>
        </tr>
      </tbody>
    </table>
  ) : isLoading ? (
                <tr>
                  <td colSpan={6}>
                    <div className='text-center'>
                      <div className='spinner-border' role='status'>
                        <span className='visually-hidden'>Loading...</span>
                      </div>
                    </div>
                  </td>
                </tr>
              ) : organisationTenantdata?.data?.length>0  ? (
            <>
            {
              selectedApplication?(
                <form>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>Domains</label>
                <div className='border border-primary rounded p-3 px-5 m-6'>
                  <div className='row mb-6'>
                    {/* Frontend Domain */}
                    <label className='col-lg-4 col-form-label required fw-bold fs-6 mb-3'>
                      Frontend Domain 1
                    </label>
                    <div className='col-lg-6 fv-row'>
                    <div style={{ position: 'relative', display: 'inline-block', width: '100%' }}>
                      <input
                        type='text'
                        className='form-control form-control-lg form-control-solid'
                        placeholder="Frontend Domain"
                        value={
                          selectedApplication.frontend_domain 
                            ? selectedApplication.frontend_domain[0]?.split('.')[0] 
                            : ''
                        }
                        onChange={(e) => {
                           handleFrontendDomainChange(e, 0)
                        }}
                      />
                      {/* Suffix displayed next to the input */}
                      <span
                                                style={{
                                                    position: 'absolute',
                                                    right: '1em',
                                                    top: '50%',
                                                    transform: 'translateY(-50%)',
                                                    pointerEvents: 'none',
                                                }}
                                            >
                                                .{applcationDataSingle?.data?.website_url}
                                            </span>
                                            </div>
                    </div>

                    <div className='col-lg-2'>
                      <button
                        className='btn btn-info fs-8'
                        type='button'
                        onClick={handleDomainVerify}
                      >
                        Verify Domain Name
                      </button>
                    </div>
                    {/* API Domain */}
                    <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                      API Domain 1
                    </label>
                    <div className='col-lg-6 fv-row'>
                    <div style={{ position: 'relative', display: 'inline-block', width: '100%' }}>
                      <input
                        type='text'
                        className='form-control form-control-lg form-control-solid'
                          placeholder="API Domain"
                          value={
                            selectedApplication.frontend_domain
                              ? `api.${selectedApplication.frontend_domain[0]?.split('.')[0]}`
                              : ''
                          }
                          readOnly
                        />
                        <span
                          style={{
                            position: 'absolute',
                            right: '1em',
                            top: '50%',
                            transform: 'translateY(-50%)',
                            pointerEvents: 'none',
                          }}
                        >
                          .{application.api_url}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className='row mb-6'>
                    {/* Frontend Domain */}
                    <label className='col-lg-4 col-form-label fw-bold fs-6 mb-3'>
                      Frontend Domain 2
                    </label>
                    <div className='col-lg-6 fv-row'>
                    <div style={{ position: 'relative', display: 'flex', alignItems: 'center', width: '100%' }}>
                    <span
                                                style={{
                                                    position: 'absolute',
                                                    left: '0.75em',
                                                    fontSize: '1rem',
                                                    pointerEvents: 'none',
                                                    color: '#6c757d',
                                                }}
                                            >
                                                {ViewApplication?.frontend_domain_prefix?ViewApplication?.frontend_domain_prefix:"office"}.
                                            </span>
                      <input
                        type='text'
                        style={{ paddingLeft: '8.5em' }}
                        className='form-control form-control-lg form-control-solid'
                        placeholder="Frontend Domain"
                        value={
                          selectedApplication.frontend_domain
                            ? // Check if the prefix matches and remove it
                              selectedApplication.frontend_domain[1]?.startsWith(
                                `${ViewApplication?.frontend_domain_prefix}`
                              )
                              ? selectedApplication.frontend_domain[1]
                                  .replace(
                                    `${ViewApplication?.frontend_domain_prefix}.`,
                                    ""
                                  ) // Remove the prefix
                              : selectedApplication.frontend_domain[1] // Keep the value as is if no match
                            : ""
                        }            
                        onChange={(e) => handleFrontendDomainChange(e, 1)} // Pass index to the function
                      />
                    </div>
                    </div>
                    {/* API Domain */}
                    <label className='col-lg-4 col-form-label fw-bold fs-6'>
                      API Domain 2
                    </label>
                    <div className='col-lg-6 fv-row'>
                    <div style={{ position: 'relative', display: 'flex', alignItems: 'center', width: '100%' }}>
                                            <span
                                                style={{
                                                    position: 'absolute',
                                                    left: '0.75em',
                                                    fontSize: '1rem',
                                                    pointerEvents: 'none',
                                                    color: '#6c757d',
                                                }}
                                            >
                                            {ViewApplication?.api_domain_prefix?ViewApplication?.api_domain_prefix:"office"}.
                                            </span>
                      <input
                        type='text'
                        className='form-control form-control-lg form-control-solid'
                        placeholder="API Domain"
                        style={{ paddingLeft: '8.5em' }}
                        value={
                          selectedApplication.domain_name
                            ? // Check if the prefix matches and remove it
                              selectedApplication.domain_name[1]?.startsWith(
                                `${ViewApplication?.api_domain_prefix}`
                              )
                              ? selectedApplication.domain_name[1]
                                  .replace(
                                    `${ViewApplication?.api_domain_prefix}.`,
                                    ""
                                  ) // Remove the prefix
                              : selectedApplication.domain_name[1] // Keep the value as is if no match
                            : ""
                        }       
                        onChange={(e) => handleAPIDomainChange(e, 1)}
                      />
                    </div>
                  </div>
                  </div>
                  <Alert variant="danger">
                    <p>
                    The first combination of the domain is compulsory and after adding it, please contact our team to set up this sub-domain for you.
                      Our devops department will provide the information of the DNS details which will be used to point out your sub-domains.
                    </p>
                  </Alert>
                </div>
                {/* Other fields... */}
                <label className='col-lg-4 col-form-label fw-bold fs-6'>IP Addresses</label>
                <div className='border border-danger rounded p-3 px-5 m-6'>
                  {ipAddresses.map((ipAddress, index) => (
                    <div className='form-group row mb-6' key={index}>
                      <label className='col-lg-2 col-form-label fw-bold fs-6'>
                        <span>IP Address {index + 1}</span>
                      </label>
                      <div className='col-lg-8 fv-row'>
                        <input
                          type='text'
                          className='form-control form-control-lg form-control-solid'
                          placeholder='IP Address'
                          value={ipAddress}
                          onChange={(e) => handleIpAddressChange(index, e.target.value)}
                        />
                      </div>
                      <div className='col-lg-2'>
                        <button
                          className='btn btn-danger mt-3'
                          type='button'
                          onClick={() => handleRemoveIpAddress(index)}
                        >
                          Remove
                        </button>
                      </div>
                    </div>
                  ))}
                  <div className='col-lg-2'>
                    <button
                      type='button'
                      className='btn btn-success mt-3'
                      onClick={handleAddIpAddress}
                    >
                      Add IP Address
                    </button>
                  </div>
                </div>
              </form>
              ):null
            }
              <div className='card-footer d-flex justify-content-end py-6 px-9'>
          {docEncVarError || spacesFolderError || selectedApplication?.frontend_domain[0].startsWith('.multitenant') ? (
            <button type='button' data-toggle="tooltip" data-placement="bottom" title="Update Application" className='btn btn-primary disabled'>
              Update Application
            </button>
          ) : (
            <button
              data-toggle="tooltip" data-placement="bottom" title="Update Application"
              type='button'
              className='btn btn-primary'
              onClick={handleUpdateApplication}
              disabled={loading}
            >
              {!loading && 'Update Application'}
              {loading && (
                <span className='indicator-progress' style={{ display: 'block' }}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          )}
        </div>
            </>
          ) : (
            <div>Please Subscribe to this Application first</div>
          )}
        </div>
      </div>
    </div>
  );
}

export default EditApplication;
