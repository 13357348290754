import {Route, Routes} from 'react-router-dom'
import {Registration} from './components/Registration'
import {ForgotPassword} from './components/ForgotPassword'
import {Login} from './components/Login'
import {AuthLayout} from './AuthLayout'
import VerifyEmail from './components/VerifyEmail'
import ResetPassword from './components/ResetPassword'
import SetPassword from './components/SetPassword'

const AuthPage = () => (
  <Routes>
    <Route element={<AuthLayout />}>
      <Route path='login' element={<Login />} />
      <Route path='email-verification' element={<VerifyEmail />} />
      <Route path='forgot-password' element={<ForgotPassword />} />
      <Route path='reset-password' element={<ResetPassword />} />
      <Route path='set-password' element={<SetPassword />} />
      <Route index element={<Login />} />
    </Route>    
    <Route path='registration' element={<Registration />} />
  </Routes>
)

export {AuthPage}
